<template>
  <div class="main user-layout-register">
    <div class="tabs">请使用已有邮箱进行账号绑定如有疑问请联系IT服务台</div>
    <a-form :form="form">
      <a-form-item>
        <a-input
          size="large"
          type="text"
          :placeholder="$t('user.register.emailPlaceholder')"
          v-decorator="[
            'email',
            { rules: [{ required: true, type: 'email', message: '请输入有效邮箱地址' }], validateTrigger: ['change'] },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          size="large"
          type="text"
          :maxLength="4"
          :placeholder="$t('user.register.emailCode')"
          v-decorator="[
            'verificationCode',
            { rules: [{ required: true, message: '请输入有效验证码' }], validateTrigger: ['change'] },
          ]"
        >
        </a-input>
      </a-form-item>
      <span
        class="getCaptcha"
        size="large"
        :disabled="state.smsSendBtn"
        @click.stop.prevent="getCaptcha"
        v-text="(!state.smsSendBtn && $t('user.register.getEmailCode')) || state.time + ' s'"
      ></span>
      <a-form-item>
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="register-button"
          :loading="bindBtn"
          @click.stop.prevent="handleSubmit"
          :disabled="!form.getFieldsValue().email || !form.getFieldsValue().verificationCode || bindBtn"
          >立即绑定</a-button
        >
        <!-- <router-link :to="{ name: 'register' }" style="float: right"> 注册新账号</router-link> -->
      </a-form-item>
    </a-form>
    <div v-show="loadingFlag" class="loadingWrap">
      <img src="https://wkg-public.oss-us-west-1.aliyuncs.com/resouce/wk-loading.gif" class="loading-img" />
    </div>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getSmsCaptcha } from '@/api/register';
import { deviceMixin } from '@/store/device-mixin';
import {
  ACCESS_TOKEN,
  ROLES,
  REMEMBERME,
  TEACHERINFO,
  USERINFOSERVER,
  REFRESH_ACCESS_TOKEN,
} from '@/store/mutation-types';

import { getUserSig, feiShuLogin, feiShuBind } from '@/api/headTeacher';
import { handleUpdateorder } from '@/api/xiaowukong';
import pic_teacher from '@/assets/teacher.png';
import pic_head_charge_lily from '@/assets/icons/pic_head_charge_lily@2x.png';
import { getTimezoneOptions, getCountryOptions } from '@/utils/geoLocation';

const session = storage.createStore(sessionStorage);

export default {
  name: 'bind',
  components: {},
  mixins: [deviceMixin],
  data() {
    return {
      form: this.$form.createForm(this),
      loadingFlag: false,
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        percent: 10,
      },
      bindBtn: false,
      interval: null,
      accountId: null,
      formIM: {},
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      const feishuCode = this.$route.query.code;
      this.loadingFlag = true;
      if (feishuCode) {
        const params = {
          code: feishuCode,
        };
        feiShuLogin(params)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              // needBinding为true代表需要绑定
              if (!res.data.content.needBinding) {
                this.loginSuccess(res.data);
              } else {
                this.loadingFlag = false;
                this.accountId = res.data.content.uuid;
              }
            }
          })
          .catch(() => {
            this.redirectToFeishu();
          });
      } else {
        this.redirectToFeishu();
      }
    },
    redirectToFeishu() {
      const redirectUri = encodeURIComponent(`${window.location.href.split('/user')[0]}/user/binding`);
      window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${redirectUri}&app_id=cli_a189c9d411f8d00c`;
    },
    loginSuccess(res) {
      // 只有班主任和小悟空角色才能登录
      const roleArr = res.content.roles;
      const roleFLag = roleArr.find(
        (role) =>
          role === 'CLASS_ADMIN' ||
          role === 'X_WUKONG' ||
          role === 'TEACHING_STAFF' ||
          role === 'TEACHING_STAFF_ADMIN' ||
          role === 'HEAD_TEACHER_SUPER' ||
          role === 'HEAD_TEACHER_LEADER',
      );
      if (!roleFLag) {
        this.$store.commit('showMessage', {
          message: '此账号暂无登录权限',
          type: 'error',
        });
        return false;
      }
      this.$store.commit('SET_INFO', res.content);

      const parms = {
        fullName: res.content.fullName,
        email: res.content.email,
      };
      storage.set(USERINFOSERVER, parms);
      this.$store.commit('SET_USERINFOSERVER', parms);
      // 获取用户的userid
      this.formIM.userID = res.content.uuid;
      const obj = {
        email: res.content.email,
        fullName: res.content.fullName,
        roleName: roleFLag,
        userID: res.content.uuid,
        phone: res.content.phone,
        roleArr,
        subject: res.content.subject,
        leader: res.content.leader, // 是否是管理员
        leaderDeptId: res.content.leaderDeptId, // 最高部门的部门ID
      };
      storage.set('userInfo', obj);
      storage.set(ACCESS_TOKEN, res.content.token);
      storage.set(REFRESH_ACCESS_TOKEN, res.content.refreshToken);
      session.set(ACCESS_TOKEN, res.content.token);
      session.set(REFRESH_ACCESS_TOKEN, res.content.refreshToken);
      storage.set(ROLES, res.content.roles);
      // 存储用户头像
      const avatar = roleFLag === 'CLASS_ADMIN' ? pic_teacher : pic_head_charge_lily;
      if (res.content.accountData !== null && res.content.accountData.avatar) {
        storage.set('avatarIM', res.content.accountData.avatar);
      } else {
        storage.set('avatarIM', avatar);
      }
      // 判断IM是否在线，在线就直接进入首页
      this.$router.push({ path: '/' });
      // setTimeout(() => {
      //   this.$notification.success({
      //     message: '欢迎'
      //   })
      // }, 1000)
      // 如果角色是班主任或者小悟空，登录IM
      this._getUserSig();
      // 缓存时区信息
      getTimezoneOptions().then((timezoneOptions) => {
        localStorage.timezoneOptions = JSON.stringify(timezoneOptions);
        localStorage.timezoneMaps = JSON.stringify(
          timezoneOptions?.reduce(
            (o, item) => ({
              ...o,
              [item.value]: item.label,
            }),
            {},
          ),
        );
      });
      // 缓存国家信息
      getCountryOptions().then((countryOptions) => {
        localStorage.countryOptions = JSON.stringify(countryOptions);
        localStorage.countryMaps = JSON.stringify(
          countryOptions?.reduce(
            (o, item) => ({
              ...o,
              [item.value]: item.label,
            }),
            {},
          ),
        );
      });
    },

    handleSubmit() {
      this.form.validateFields({ force: true }, (err, values) => {
        if (!err) {
          const bindParams = { ...values };
          bindParams.accountId = this.accountId;
          this.onBind(bindParams);
        }
      });
    },
    onBind(data) {
      this.bindBtn = true;
      const params = data;
      feiShuBind(params)
        .then((res) => {
          if (res.status === 200) {
            this.bindBtn = false;
            this.loginSuccess(res.data);
          }
        })
        .catch(() => {
          this.bindBtn = false;
        });
    },

    // 发送验证码
    getCaptcha(e) {
      e.preventDefault();
      const {
        form: { validateFields },
        state,
        $message,
        // $notification
      } = this;
      const that = this;
      validateFields(['email'], (err, values) => {
        if (!err) {
          state.smsSendBtn = true;
          this.interval = window.setInterval(() => {
            // eslint-disable-next-line no-plusplus
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(that.interval);
            }
          }, 1000);

          const hide = $message.loading('验证码发送中..', 0);

          getSmsCaptcha({ email: values.email })
            .then(() => {
              setTimeout(hide, 2500);
            })
            // eslint-disable-next-line no-shadow
            .catch((e) => {
              setTimeout(hide, 1);
              clearInterval(that.interval);
              state.time = 60;
              state.smsSendBtn = false;
              this.requestFailed(e);
            });
        }
      });
    },
    requestFailed(err) {
      this.$notification.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4,
      });
      this.bindBtn = false;
    },
    // 获取IM的UserSig
    _getUserSig() {
      getUserSig().then((res) => {
        this.formIM.userSig = res.data.content.userSig;
        this.formIM.sdkAppId = res.data.content.sdkAppId;
        session.set('sdkAppId', this.formIM.sdkAppId);
        this.loginIM();
      });
    },
    // 登录IM
    loginIM() {
      this.tim
        .login({
          userID: this.formIM.userID,
          userSig: this.formIM.userSig,
        })
        .then(() => {
          // 刷新浏览器，IM会断开vuex会清空useSig
          const obj = {
            userID: this.formIM.userID,
            userSig: this.formIM.userSig,
          };

          // session.set('userObj', obj)
          storage.set('userObj', obj, 7 * 24 * 60 * 60 * 1000);

          this.$store.commit('startComputeCurrent');

          this.$store.commit({
            type: 'GET_USER_INFO',
            userID: this.formIM.userID,
            userSig: this.formIM.userSig,
            sdkAppID: this.formIM.sdkAppId,
          });
          // 小悟空 登录调用接单
          const roles = storage.get('roles');
          const roleFLag = roles.find((role) => role === 'CLASS_ADMIN' || role === 'X_WUKONG');
          if (storage.get('userInfo').roleArr.includes('X_WUKONG')) {
            this.handlechange();
          }
        })
        .catch((error) => {});
    },
    // TODU 小悟空接单逻辑 角色是小悟空时 才调用
    handlechange() {
      const parms = {
        id: this.formIM.userID,
        type: 'online',
        batch: false,
      };
      handleUpdateorder(parms).then((res) => {
        if (res.status != 200) {
        }
      });
    },
  },
};
</script>
<style lang="less">
.user-layout-register {
  .ant-input-group-addon:first-child {
    background-color: #fff;
  }
  .ant-input-password .ant-input-lg {
    height: 64px;
    border-radius: 14px;
    &:hover {
      border-color: #009cff !important;
      border-right-width: 1px !important;
    }
  }
}
</style>
<style lang="less" scoped>
.tabs {
  padding: 10% 20% 0;
  font-size: 16px;
  color: #009cff;
  margin-bottom: 30px;
  text-align: center;
}
.user-layout-register {
  .ant-form-item {
    margin-bottom: 36px;
  }
  .ant-form-item-with-help {
    margin-bottom: 17px;
  }
  .ant-input,
  .ant-input-password .ant-input-lg {
    height: 64px;
    border-radius: 14px;
  }
  .ant-input:hover {
    border-color: #009cff !important;
    // border-right-width: 1px !important;
  }
  .getCaptcha {
    position: relative;
    max-width: 80px;
    border: none;
    color: #009cff;
    float: right;
    margin-top: -80px;
    margin-right: 40px;
    border-color: transparent;
  }
  .register-button {
    height: 64px;
    border-radius: 14px;
    width: 100%;
    font-size: 24px;
    color: #fff;
    // background-color: #009CFF;
    border: none;
  }
  .register-tips {
    font-size: 14px;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-bottom: 0;
  }
}
.loadingWrap {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
}
.loading-img {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
